import ReactDOMClient from 'react-dom/client';
import React from 'react';

export default async function init() {
  if (typeof window === 'undefined') {
    return;
  }

  if (typeof React === 'undefined' || typeof ReactDOMClient === 'undefined') {
    throw new Error(
      'facade did not provide LIBS[react, react-dom] required for creatives core'
    );
  }

  // @ts-expect-error - игнорируем ошибку, т.к. переменную никуда не прокидываем, а протестить запрос нужно
  const creatives = await getCreatives().catch(console.error);
}

function getCreatives() {
  const { CONSTANTS } = window;

  return fetch(
    `${CONSTANTS.RTB_API}/getStories?site=${CONSTANTS.RTB_SITE_ID}&siebel_id=${CONSTANTS.RTB_SIEBEL_ID}}`,
    {
      method: 'GET',
      headers: { ContentType: 'application/json' },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data.payload.creatives;
    });
}
